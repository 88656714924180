<template>
  <div class="data-table">
    <div class="grid-container bg-primary-100 text-white" style="padding-top: 21px; padding-bottom: 16px">
      <p></p>
      <p class="text-center font-bold">{{ displayText.type }}</p>
      <p class="text-center font-bold">{{ displayText.exp }}</p>
      <p class="text-center font-bold">{{ displayText.name }}</p>
      <p class="text-center font-bold">{{ displayText.notify }}</p>
    </div>
    <BaseElCheckboxGroup v-model="syncData" class="bg-white">
      <div v-for="product in displayData" :key="product.id">
        <hr>
        <div class="grid-container row">
          <BaseElCheckbox class="px-[20px]" :label="product.id">
            <p></p>
          </BaseElCheckbox>
          <p class="text-center">{{ product.type }}</p>
          <p class="text-center"> {{ product.exp }}</p>
          <p class="text-center">{{ product.name }}</p>
          <p class="text-center">
            <Tag :type="product.notify.type">{{ product.notify.content }}</Tag>
          </p>
        </div>
      </div>
    </BaseElCheckboxGroup>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get, map } from 'lodash'
import { useCoupon } from '@/use/useCoupon'
import { couponTypeConfig } from '@/config/couponExchange'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'CouponNotificationTable',
  props: {
    tableData: { type: Array, defalt: () => [] },
    allData: { type: Array, defalt: () => [] },
    selected: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { formatExpPreviewText } = useCoupon()
    const syncData = useVModel(props, 'selected', emit)
    const displayText = computed(() => {
      const data = {
        type: '類型',
        exp: '有效期限',
        name: '名稱',
        notify: '通知開啟狀態',
      }
      return data
    })
    const displayData = computed(() => {
      const data = props.tableData
      return map(data, (i) => {
        const { id, name, type, isExp, expType, expDay, endOfPeriodUnit, expAt } = i.Coupon
        return {
          id,
          name,
          type: get(couponTypeConfig, `${type}.label`),
          exp: formatExpPreviewText({
            isExp,
            expType,
            expDay,
            endOfPeriodUnit,
            expAt,
          }),
          notify: {
            type: i.notify ? 'action' : 'info',
            content: i.notify ? '開啟' : '關閉',
          }
        }
      })
    })
    return {
      displayData,
      syncData,
      displayText,
    }
  },
})
</script>
<style scope lang="postcss">
.grid-container {
  @apply grid gap-[20px];
  @apply text-normal;
  grid-template-columns: 50px 1fr 1fr 1fr 1fr;
}
.el-table {
  border-radius: 0;
}
.row {
  @apply py-[12px] items-center
}
.row:hover {
  @apply bg-[#F5F7FA];
}
</style>
