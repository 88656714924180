<template>
  <div class="advance-coupon-record-notification">
    <PageTitle
      :title="displayText.pageTitle"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'LineSettings' })"
    />
    <BaseElForm label-position="left" label-width="200px">
      <BaseElFormItem class="mb-[10px]">
        <template #label>
          <FormItemTooltipLabel :label="displayText.defaultCouponSetting.title" :tooltipWidth="200" :iconSize="16">
            {{ displayText.defaultCouponSetting.tooltip }}
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="defaultCouponSetting.notify"
          :active-text="displayText.defaultCouponSetting.activeText"
          :inactive-text="displayText.defaultCouponSetting.inactiveText"
          @change="updateSetting()"
        />
      </BaseElFormItem>
    </BaseElForm>
    <FilterContainer>
      <BaseElInput
        v-model="search.name"
        :placeholder="displayText.placeholder.name"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect v-model="search.type" :placeholder="displayText.placeholder.type" clearable @change="refresh(true)">
        <BaseElSelectOption v-for="option in availableCouponType" :key="option.value" :label="option.label" :value="option.value" />
      </BaseElSelect>
    </FilterContainer>
    <el-alert
      v-if="showSelectAlert"
      type="success"
      center
      class="select-alert"
      :closable="false"
    >
      <p class="text-normal" v-html="alertMessage" />
    </el-alert>
    <p class="text-sub text-info font-medium mb-[10px] flex items-center gap-[10px]">
      <MaterialIcon :size="16">info_outline</MaterialIcon>
      {{ displayText.info }}
    </p>
    <div class="controls relative">
      <BaseElCheckbox
        v-model="isAllSelected"
        :indeterminate="isIndeterminate"
        @change="onToggleSelect()"
      />
      <BaseElSelect v-model="selectedOption" class="selectOption" @change="onChangeSelectOption">
        <BaseElSelectOption value="selectAll">{{ displayText.selectOptions.selectAll }}</BaseElSelectOption>
        <BaseElSelectOption value="unSelectAll">{{ displayText.selectOptions.unSelectAll }}</BaseElSelectOption>
        <BaseElSelectOption value="open">{{ displayText.selectOptions.open }}</BaseElSelectOption>
        <BaseElSelectOption value="close">{{ displayText.selectOptions.close }}</BaseElSelectOption>
      </BaseElSelect>
      <BaseElButton type="text" class="toggleBtn" @click="updateSetting(true)">
        <MaterialIcon :size="16">notifications_outline</MaterialIcon>{{ displayText.btn.open }}
      </BaseElButton>
      <BaseElButton type="text" class="toggleBtn" @click="updateSetting(false)" >
        <MaterialIcon :size="16">notifications_off_outline</MaterialIcon>{{ displayText.btn.close }}
      </BaseElButton>
    </div>
    <CouponNotificationTable
      v-loading="loading.table"
      :tableData="displayData[tableOptions.page - 1]"
      :allData="displayData"
      :selected.sync="selected"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="flattenDeep(displayData).length"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import PageTitle from '@/components/Title/PageTitle'
import CouponNotificationTable from './components/CouponNotificationTable'
import { defineComponent, reactive, computed, onMounted, ref } from 'vue'
import { chunk, flattenDeep, get, map, filter, isNull, isEmpty, every, includes, orderBy } from 'lodash'
import { useTable } from '@/use/table'
import { couponTypeConfig } from '@/config/couponExchange'
import { GetCouponRecordAdvancedSetting, UpdateCouponRecordAdvancedSetting } from '@/api/shop'
import MaterialIcon from '@/components/MaterialIcon.vue'

export default defineComponent({
  name: 'AdvanceCouponRecordNotification',
  components: {
    PageTitle,
    FilterContainer,
    CouponNotificationTable,
    FormItemTooltipLabel,
    MaterialIcon,
  },
  setup () {
    const {
      tableData,
      tableOptions,
      loading,
      shopId,
    } = useTable()
    tableOptions.pageLimit = 10
    const search = reactive({
      name: null,
      type: null,
    })
    const selectedOption = ref(null)
    const selected = ref([])
    const isAllSelected = ref(false)
    const defaultCouponSetting = reactive({
      notify: false,
    })
    const uniqueCouponTypes = computed(() => {
      const types = map(tableData.value, item => get(item, 'Coupon.type'))
      return [...new Set(types)]
    })
    const availableCouponType = computed(() => {
      return filter(couponTypeConfig, item => includes(uniqueCouponTypes.value, item.value))
    })
    const showSelectAlert = computed(() => {
      if (isNull(selectedOption.value) || includes(['selectAll', 'unSelectAll'], selectedOption.value)) return false
      const notifyStatus = selectedOption.value === 'open'
      const allMatchingNotifyIds = map(filter(tableData.value, item => item.notify === notifyStatus), item => get(item, 'Coupon.id'))
      return allMatchingNotifyIds.length === selected.value.length && every(allMatchingNotifyIds, id => includes(selected.value, id))
    })
    const alertMessage = computed(() => (selectedOption.value === 'open' ? get(displayText.value, 'alert.open') : get(displayText.value, 'alert.close')))
    const displayText = computed(() => {
      const data = {
        pageTitle: '票券發放通知進階設定',
        defaultCouponSetting: {
          title: '新票券通知預設狀態',
          tooltip: '於票券設定新增的票券，其初始的通知預設狀態。',
          activeText: '開啟通知',
          inactiveText: '關閉通知',
        },
        placeholder: {
          name: '請輸入名稱',
          type: '選擇票券類型',
        },
        alert: {
          open: '您已選取全部 <b>已開啟通知</b> 狀態的票券',
          close: '您已選取全部 <b>已關閉通知</b> 狀態的票券',
        },
        info: '外層通知必須為啟用狀態，此頁設定才會生效。',
        selectOptions: {
          selectAll: '全選',
          unSelectAll: '全不選',
          open: '已開啟通知',
          close: '已關閉通知',
        },
        btn: {
          open: '開啟通知',
          close: '關閉通知',
        }
      }
      return data
    })
    const displayData = computed(() => {
      return chunk(filterData.value, tableOptions.pageLimit)
    })
    const filterData = computed(() => {
      let data = tableData.value
      if (search.name) data = filter(data, i => includes(i.Coupon.name, search.name))
      if (search.type) data = filter(data, i => i.Coupon.type === search.type)
      return orderBy(data, ['Coupon.createdAt'], ['desc'])
    })
    const refresh = async (search) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getCouponRecordAdvancedSetting()
      loading.table = false
    }
    const getCouponRecordAdvancedSetting = async () => {
      const [res, err] = await GetCouponRecordAdvancedSetting({
        shopId: shopId.value,
        eventType: 'couponRecordOpen',
      })
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = get(res, 'couponSettings')
      defaultCouponSetting.notify = get(res, 'defaultCouponSetting.notify')
    }
    const updateSetting = async (status) => {
      const notifyValue = status
      if (!isEmpty(notifyValue) && isEmpty(selected.value)) {
        window.$message.error('請選擇票券')
        return
      }
      const couponSettings = map(tableData.value, item => {
        const couponId = get(item, 'Coupon.id')
        const isSelected = includes(selected.value, couponId)
        return {
          couponId,
          notify: isSelected && !isNull(notifyValue) ? notifyValue : item.notify,
        }
      })
      const [, err] = await UpdateCouponRecordAdvancedSetting({
        shopId: shopId.value,
        eventType: 'couponRecordOpen',
        defaultCouponSetting,
        couponSettings,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      await refresh()
      selectedOption.value = null
      isAllSelected.value = false
      selected.value = []
    }
    const onToggleSelect = () => {
      if (isAllSelected.value) {
        selected.value = map(filterData.value, 'Coupon.id')
      } else {
        selected.value = []
      }
    }
    const onChangeSelectOption = (value) => {
      switch (value) {
        case 'selectAll':
          selected.value = map(filterData.value, 'Coupon.id')
          isAllSelected.value = true
          break
        case 'unSelectAll':
          selected.value = []
          isAllSelected.value = false
          break
        case 'open':
          selected.value = map(filter(filterData.value, item => item.notify ), 'Coupon.id')
          break
        case 'close':
          selected.value = map(filter(filterData.value, item => !item.notify ), 'Coupon.id')
          break
      }
    }
    const isIndeterminate = computed(() => {
      const totalItems = filterData.value.length
      const selectedItems = selected.value.length
      return selectedItems > 0 && selectedItems < totalItems
    })
    onMounted(async () => {
      await refresh()
    })
    return {
      search,
      availableCouponType,
      tableData,
      tableOptions,
      loading,
      displayData,
      flattenDeep,
      refresh,
      defaultCouponSetting,
      updateSetting,
      selectedOption,
      onChangeSelectOption,
      onToggleSelect,
      selected,
      showSelectAlert,
      alertMessage,
      isAllSelected,
      isIndeterminate,
      displayText,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .page-title {
  @apply mb-[10px]
}
::v-deep .table-filters-container {
  @apply mb-[10px]
}
.controls {
  @apply flex gap-[10px] bg-white px-[24px] items-center;
  border-radius: 6px 6px 0 0;
}
::v-deep .toggleBtn span {
  @apply flex gap-[6px] items-center
}
::v-deep .selectOption .el-input {
  @apply w-[25px]
}
::v-deep .selectOption .el-input__inner {
  @apply hidden
}
.select-alert {
  @apply mb-[10px] bg-primary-30
}
::v-deep .select-alert .el-alert__description {
  @apply text-primary-100 mt-0
}
</style>
